import Noodl from "@noodl/noodl-sdk";
import React, { useCallback, useEffect, useRef } from "react";
import Chart from "chart.js/auto";

function ChartComponent({ type, data, options, onClick, clickedDatasetIndex }) {
  const canvasRef = useRef(null);
  const chartRef = useRef(null);

  const clickHandler = useCallback(
    (event, elements) => {
      const clickedElement = elements[0];

      if (!!clickedElement) {
        clickedDatasetIndex(clickedElement.datasetIndex);
        onClick(clickedElement.datasetIndex);
      }
    },
    [data]
  );

  // onMount: set up chart
  useEffect(() => {
    chartRef.current = new Chart(canvasRef.current, {
      type,
      data,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        resizeDelay: 50, // ms debounce
        onClick: clickHandler,
        ...options,
      },
    });
  }, []);

  // update chart data when input has changed
  useEffect(() => {
    chartRef.current.data = data;
    chartRef.current.update();
  }, [data]);

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
      }}
    >
      <canvas ref={canvasRef} width="1200" height="600" id="Prodikt-chartjs"></canvas>
    </div>
  );
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.warn("Prodikt Chart error", { error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      this.props.onError();
      return null;
    }

    return this.props.children;
  }
}

function ProdiktChart(props) {
  return (
    <ErrorBoundary onError={props.onError}>
      <ChartComponent {...props} />
    </ErrorBoundary>
  );
}

const ProdiktChartNode = Noodl.defineReactNode({
  name: "Prodikt Chart",
  getReactComponent() {
    return ProdiktChart;
  },
  inputProps: {
    type: { type: "string", default: "bar" },
    data: { type: "array", default: {} },
    options: { type: "object", default: {} },
  },
  outputProps: {
    onClick: { type: "signal", displayName: "Click" },
    clickedDatasetIndex: {
      type: "number",
      displayName: "Clicked Dataset Index",
    },
    onError: { type: "signal", displayName: "Failure" },
  },
});

Noodl.defineModule({
  reactNodes: [ProdiktChartNode],
  nodes: [],
  setup() {
    //this is called once on startup
  },
});
